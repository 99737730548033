import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, Navigate } from "react-router-dom";
import SearchAttendance from "./SearchAttendance";
import MarkAttendanceModal from "../UI/PopUp/MarkAttendanceModal";
import AttendaceRecord from "./AttendaceRecord";
import {
  Card,
  DatePicker,
  Segmented,
  Table,
  Tag,
  Button,
  Form,
  Input,
  Row,
  Col,
  Pagination,
  Typography,
  Select,
} from "antd";
import BtnSearchSvg from "../UI/Button/btnSearchSvg";
import { VioletLinkBtn } from "../UI/AllLinkBtn";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { CsvLinkBtn, TableHeraderh2 } from "../UI/CsvLinkBtn";
import {
  loadAllAttendancePaginated,
  clearAttendanceList,
  loadAllAttendance,
  filterAttendanceList,
} from "../../redux/rtk/features/attendance/attendanceSlice";
import AttendanceDelete from "./attendanceDelete";
import AttendanceEditPopup from "../UI/PopUp/AttendanceEditPopUp";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlineSearchOff } from "react-icons/md";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import DelModal from "../UI/PopUp/DeleteWarningModal";
import moment from "moment";
//Date fucntinalities
let startdate = dayjs().startOf("month");
let enddate = dayjs().endOf("month");

// function CustomTable({ Attendancefilter, total, status, setStatus, loading }) {

// 	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

// 	const CSVlist = Array.isArray(Attendancefilter?.attendanceData) && Attendancefilter?.attendanceData.length > 0 ? Attendancefilter?.attendanceData?.map((i) => ({
// 		...i,
// 		supplier: i?.supplier?.name,
// 	}))
// 		:
// 		"";

// 	const [modalVisible, setModalVisible] = useState(false);

// 	const handleOpenModal = () => {
// 		setModalVisible(true);
// 	};

// 	const handleCloseModal = () => {
// 		setModalVisible(false);
// 	};

// 	const handleAttendanceSearch = (attendanceData) => {
// 		// Handle the attendance search data here
// 		console.log(attendanceData);
// 		// Close the modal after handling
// 		handleCloseModal();
// 	};
// 	return (
// 		<div className="p-3 mt-4 mb-4 !mr-0 ant-shadow rounded-[20px]">
// 			<div className="flex justify-between">
// 				<div className='mr-3 text-center flex justify-start'>
// 					<CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] ">
// 						<CSVLink className="m-3 text-white hover:!text-white" data={CSVlist} filename='attendance'>
// 							CSV
// 						</CSVLink>
// 					</CsvLinkBtn>
// 				</div>
// 				<div className="text-center mt-4 mr-3 flex justify-end">
// 					<Button type="primary" onClick={handleOpenModal}>
// 						Add Attendance
// 					</Button>
// 					<MarkAttendanceModal
// 						visible={modalVisible}
// 						onClose={handleCloseModal}
// 						onSearch={handleAttendanceSearch}
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

const GetAllAttendance = (props) => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.attendance);
  const { Attendancefilter } = useSelector((state) => state.attendance);
  const AllEmp = useSelector((state) => state.users.list);
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [row, setRow] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [attendanceDel, setAttendanceDel] = useState(false);
  const [attendanceId, setAttendanceId] = useState();
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [employeeId, setEmployeeId] = useState("");
  const [form] = Form.useForm();
  const [status, setStatus] = useState("true");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const total_length = Attendancefilter?.attendanceData?.length
    ? Attendancefilter?.attendanceData?.length
    : 5;

  useEffect(() => {
    const data = `query=all&page=${currentPage}&count=${10000}`;
    dispatch(loadAllAttendance(data));
  }, []);

  useEffect(() => {
    if (Attendancefilter?.attendanceData) {
      makeRow();
    } else {
      const data = `query=all&page=${currentPage}&count=${10000}`;
      dispatch(loadAllAttendance(data));
    }
  }, [Attendancefilter?.attendanceData]);

  const columns = [
    {
      title: "ID",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (user) => `${user?.firstName} ${user?.lastName}`,
    },
    {
      title: "In Time",
      dataIndex: "intime",
      key: "intime",
      render: inTime => inTime ? inTime : "--",
    },
    {
      title: "Out Time ",
      dataIndex: "outtime",
      key: "outtime",
      render: outTime => outTime ? outTime : "--",

    },
    // {
    //   title: "In Date",
    //   dataIndex: "intime",
    //   key: "intime",
    //   render: inTime => inTime ? inTime : "--",

    // },
    // {
    //   title: "Out Date ",
    //   dataIndex: "updatedAt", // Changed from "outtime" to "updatedAt"
    //   key: "outDate", // Key can also be adjusted to match the dataIndex if needed
    //   render: updatedAt => {
    //     return updatedAt ? moment(updatedAt).format("DD-MM-YYYY") : "--"
    //   },
    // },    
    {
      title: "In Status",
      dataIndex: "inTimeStatus",
      key: "inTimeStatus",
      render: (inTimeStatus) => {
        // use Tag component from antd to show status in different colors like green, red, yellow etc based on the status value
        if (inTimeStatus === "Late") {
          return <Tag color="red">{inTimeStatus.toUpperCase()}</Tag>;
        } else if (inTimeStatus === "Early") {
          return <Tag color="blue">{inTimeStatus.toUpperCase()}</Tag>;
        } else if (inTimeStatus === "OnTime") {
          return <Tag color="green">{inTimeStatus.toUpperCase()}</Tag>;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Out Status",
      dataIndex: "outTimeStatus",
      key: "outTimeStatus",
      render: (outTimeStatus) => {
        // use Tag component from antd to show status in different colors like green, red, yellow etc based on the status value
        if (outTimeStatus === "Late") {
          return <Tag color="blue">{outTimeStatus.toUpperCase()}</Tag>;
        } else if (outTimeStatus === "Early") {
          return <Tag color="red">{outTimeStatus.toUpperCase()}</Tag>;
        } else if (outTimeStatus === "OnTime") {
          return <Tag color="green">{outTimeStatus.toUpperCase()}</Tag>;
        }
        else if (outTimeStatus === "Check out by system") {
          return <Tag color="yellow">{outTimeStatus.toUpperCase()}</Tag>;
        }

        else {
          return "--";
        }
      },
    },
    {
      title: "Total Hour",
      dataIndex: "totalHour",
      key: "totalHour",
      render: (totalHour) => totalHour || "--",
    },
    {
      title: "Over Time",
      dataIndex: "overtime",
      key: "overtime",
      render: (overtime) => overtime || "--",
    },
    {
      title: "Attendence Date",
      dataIndex: "attendenceDate",
      key: "attendenceDate",
    },
    {
      title: "Attendence Status",
      dataIndex: "attendenceStatus",
      key: "attendenceStatus",
      render: (attendenceStatus) => {
        if (attendenceStatus === "present") {
          return <Tag color="green">{attendenceStatus.toUpperCase()}</Tag>;
        } else if (attendenceStatus === "leave") {
          return <Tag color="yellow">{attendenceStatus.toUpperCase()}</Tag>;
        } else if (attendenceStatus === "holiday") {
          return <Tag color="blue">{attendenceStatus.toUpperCase()}</Tag>;
        } else if (attendenceStatus === "absent") {
          return <Tag color="red">{attendenceStatus.toUpperCase()}</Tag>;
        } else {
          return "--";
        }
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action) => (
        <div className="flex justify-end">
          {/* <UserPrivateComponent permission={"update-attendance"}>
						<AttendanceEditPopup id={action.id} data={action} />
					</UserPrivateComponent> */}
          <UserPrivateComponent permission={"delete-attendance"}>
            <button
              type="primary"
              onClick={() => onDelete(action.id)}
              className="mr-3 ml-2"
            >
              <MdDelete size={25} color="#FF0000"/>
            </button>
          </UserPrivateComponent>
        </div>
      ),
    },
  ];
  function onDelete(id) {
    setAttendanceId(id);
    setAttendanceDel(true);
  }

  const handleSearch = () => {
    if (fromDate == "" || fromDate == null || fromDate == undefined) {
      return toast.error("From Date is required");
    }
    if (toDate == "" || toDate == null || toDate == undefined) {
      return toast.error("To Date is required");
    }
    let data = "";
    if (employeeId != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "id=" + employeeId;
    }
    if (fromDate != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "createdAtFrom=" + moment(fromDate).format('YYYY-MM-DD');
    }

    if (toDate != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "createdAtTo=" + moment(toDate).format('YYYY-MM-DD');
    }

    dispatch(loadAllAttendance(data));
  };

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const makeRow = () => {
    const filterdata = Attendancefilter?.attendanceData;
    const data =
      Array.isArray(filterdata) && filterdata.length > 0
        ? filterdata.map((data, index) => ({
          employeeId: data?.user.employeeId,
          user: data?.user == null ? "-" : data?.user,
          intime: data?.inTime,
          outtime: data?.outTime,
          updatedAt: data?.updatedAt, // Ensure this is mapped
          inTimeStatus: data?.inTimeStatus == null ? "-" : data?.inTimeStatus,
          outTimeStatus:
            data?.outTimeStatus == null ? "-" : data?.outTimeStatus,
          totalHour: data?.totalHour,
          attendenceStatus:
            data?.attendenceStatus == null ? "-" : data?.attendenceStatus,
          action: data,
          attendenceDate:
            data?.date == null
              ? "--"
              : dayjs(data?.date).format("DD-MM-YYYY"),
          overtime: data?.overtime == null ? "--" : data?.overtime,
        }))
        : filterdata;
    setRow(data);
  };
  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const onCalendarChange = (dates) => {
    startdate = (dates?.[0]).format("DD-MM-YYYY");
    enddate = (dates?.[1]).format("DD-MM-YYYY");
  };

  const onClickSearch = () => {
    // dispatch(clearAttendanceList());
    dispatch(
      loadAllAttendancePaginated({
        page: 1,
        limit: 30,
        startdate,
        enddate,
      })
    );
  };

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  const ClearFilter = () => {
    dispatch(
      loadAllAttendance(`query=all&page=${currentPage}&count=${1000}`)
    );
    setEmployeeId("");
    const currentDate = new Date().toISOString().split("T")[0];
    setFromDate(currentDate);
    setToDate(currentDate);
  };

  const CSVlist =
    Array.isArray(Attendancefilter?.attendanceData) &&
      Attendancefilter?.attendanceData.length > 0
      ? Attendancefilter?.attendanceData?.map((i) => ({
        id: i?.id,
        Name:
          i?.user.firstName == null
            ? "-"
            : i?.user?.firstName + " " + i?.user?.lastName,
        InTime: i?.inTime == null ? "-" : dayjs(i?.inTime).format("h:mm A"),
        OutTime:
          i?.outTime == null ? "-" : dayjs(i?.outTime).format("h:mm A"),
        InDate:
          i?.inTime == null ? "-" : dayjs(i?.inTime).format("DD-MM-YYYY"),
        OutDate:
          i?.outTime == null ? "-" : dayjs(i?.outTime).format("DD-MM-YYYY"),
        InStatus: i?.inTimeStatus == null ? "-" : i?.inTimeStatus,
        OutStatus: i?.outTimeStatus == null ? "-" : i?.outTimeStatus,
        TotalHour: i?.totalHour == null ? "-" : i?.totalHour,
        OverTime: i?.overtime == null ? "-" : i?.overtime,
        AttendenceStatus:
          i?.attendenceStatus == null ? "-" : i?.attendenceStatus,
      }))
      : "Not found";

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAttendanceSearch = (attendanceData) => {
    handleCloseModal();
  };

  function onChange(value) {
    setEmployeeId(value);
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  const handleDateChange = (date, dateString) => {
    setFromDate(date);
  };
  const handletoDateChange = (date, dateString) => {
    setToDate(date);
  };
  return (
    <>
      <h3 className="department-list-title text-color-2 text-[24px] font-[600]">
        Attendance
      </h3>
      <Card className="card card-custom border-0" bordered={false}>
        <div className="card-body ">
          <div>
            <AttendaceRecord data={Attendancefilter} />
          </div>
          <div className="mt-3">
            <div className="card ant-shadow rounded-[20px] w-[100%] border-0 p-0">
              <div className="ant-card-body px-[24px] py-[10px]">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSearch}
                  autoComplete="off"
                  initialValues={{
                    remember: true,
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                      <Typography variant="subtitle" className="mb-[0.4rem]">
                        From:
                      </Typography>
                      <DatePicker
                        value={fromDate ? moment(fromDate) : null}
                        onChange={handleDateChange}
                        format="DD-MM-YYYY"
                        style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                      <Typography variant="subtitle" className="mb-[0.4rem]">
                        To:
                      </Typography>

                      <DatePicker
                        value={toDate ? moment(toDate) : null}
                        onChange={handletoDateChange}
                        format="DD-MM-YYYY"
                        style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                      <Typography variant="subtitle" className="mb-[0.4rem]">
                        Employee Name:
                      </Typography>
                      <Select
                        showSearch
                        allowClear
                        style={{
                          width: "100%",
                          boxShadow: "0px 0px 5px gray",
                          borderRadius: "5px",
                        }}
                        placeholder="Select a Name"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {AllEmp &&
                          AllEmp.map((emp) => (
                            <Select.Option key={emp.id} value={emp?.id}>
                              {emp?.firstName + " " + emp.lastName}
                            </Select.Option>
                          ))}
                      </Select>
                     
                    </Col>
                    <Col
                      // xs={12} sm={12} md={6} lg={3}
                      xs={{ span: 12, offset: 0 }}
                      sm={{ span: 6, offset: 0 }}
                      lg={{ span: 6, offset: 0 }}
                      xl={{ span: 3, offset: 0 }}
                      md={{ span: 6, offset: 0 }}
                    >
                      <Form.Item>
                        <Button
                          className="w-full justify-center mt-[1.85rem] !px-[12px] !py-0 !h-[34px] flex items-center text-[15px] bg-[#E42976] text-white border-0"
                          type="buttom"
                          shape="round"
                          htmlType="submit"
                          size="large"
                        >
                          <SearchOutlined style={{ fontSize: "20px" }} />
                          Search
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col
                      // xs={12} sm={12} md={6} lg={3}
                      xs={{ span: 12, offset: 0 }}
                      sm={{ span: 6, offset: 0 }}
                      lg={{ span: 6, offset: 0 }}
                      xl={{ span: 3, offset: 0 }}
                      md={{ span: 6, offset: 0 }}
                    >
                      {" "}
                      <Form.Item>
                        <Button
                          className="w-full justify-center bg-[#FF0000] text-[15px] mt-[1.85rem] !px-[12px] !py-0 !h-[34px] text-white hover:text-white border-0 hover:border-0 flex items-center"
                          shape="round"
                          size="large"
                          onClick={ClearFilter}
                        >
                          <MdOutlineSearchOff style={{ fontSize: "20px" }} />
                          Clear
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card card-custom ant-shadow border-0 rounded-[20px] mt-3 p-2 !mr-0"
          bordered={false}
        >
          <div className="flex justify-between items-center mb-2 mt-2">
            {Attendancefilter &&
              Attendancefilter?.attendanceData?.length > 0 ? (
              <div className="mr-3 text-center flex justify-start">
                <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] ">
                  <CSVLink
                    className="m-3 text-white hover:!text-white text-[15px] font-[400]"
                    data={CSVlist}
                    filename="attendance"
                  >
                    CSV
                  </CSVLink>
                </CsvLinkBtn>
              </div>
            ) : (
              <div className="mr-3 text-center flex justify-start">
                No data to export
              </div>
            )}
            <div className="text-center mr-3 flex justify-end">
              <Button
                type="button"
                className="text-[15px] font-[400]  bg-[#E42976] text-white border-0"
                onClick={handleOpenModal}
              >
                Add Attendance
              </Button>
              <MarkAttendanceModal
                visible={modalVisible}
                onClose={handleCloseModal}
                onSearch={handleAttendanceSearch}
              />
            </div>
          </div>

          <Table
              scroll={{ x: true }}
              loading={loading}
              pagination={{
                pageSize: itemsPerPage,
              }}
              columns={columnsToShow}
              dataSource={row}
          />

        </div>
      </Card>
      <DelModal
        open={attendanceDel}
        onClose={setAttendanceDel}
        attendanceId={attendanceId}
        content={"Are you sure to delete this attendance?"}
        type={"attendancedel"}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
    </>
  );
};

export default GetAllAttendance;
