import { Button, Modal, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import styles from "./meeting.module.css";
import { useDispatch, useSelector } from "react-redux";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { loadSinglemeetingUser, loadAllMeeting } from "../../redux/rtk/features/Meeting/MeetingSlice";
import axios from "axios";
import {toast} from "react-toastify";
import {MdDelete} from "react-icons/md";

const MeetingTeamDetails = ({ userData, open, onclose }) => {
  const MeetingAll = useSelector((state) => state.Meeting.allMeeting);
  const singleUser = useSelector((state) => state.Meeting.singleMeetingUser);
  const loading = useSelector((state) => state.Meeting.loading);

  const { Title } = Typography;
  const [swape, setSwape] = useState(false);
  const dispatch = useDispatch();

  function HandleSingleMeeting(id) {
    dispatch(loadSinglemeetingUser(id));
    setSwape(true);
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department) =>
        department?.name == null ? "-" : department?.name,
    },
    {
      title: "Date",
      dataIndex: "meetingdate",
      key: "meetingdate",
      render: (meetingdate) => dayjs(meetingdate).format("YYYY-MM-DD"),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => dayjs(startTime).format("h:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => dayjs(endTime).format("h:mm A"),
    },
    {
      title: "Meeting Type",
      dataIndex: "meetingType",
      key: "meetingType",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location) =>
        location?.locationName == null ? "--" : location?.locationName,
    },
    {
      title: "Link",
      dataIndex: "meetingLink",
      key: "meetingLink",
      render: (meetingLink) => (meetingLink == null ? "--" : meetingLink),
    },
    {
      title: "Members",
      dataIndex: "id",
      key: "id",
      render: (id) => (
          <div className="flex items-center">
            <UserPrivateComponent permission={"readAll-meeting"}>
              <button
                  onClick={(e) => HandleSingleMeeting(id)}
                  className="text-[#23B9F8] text-lg font-bold px-2 mr-2"
              >
                <i className="bi bi-eye-fill"></i>
              </button>
            </UserPrivateComponent>
            <UserPrivateComponent permission={"delete-meeting"}>
              <a
                  onClick={() => showDeleteConfirm(id)}
                  type="danger"
                  className="text-lg text-[#FF0000] font-bold px-2 mr-2"

              >
                <MdDelete className="text-[25px] text-[#FF0000]" />
              </a>

            </UserPrivateComponent>
          </div>
      ),
    },
  ];
  const MembersColumns = [
    {
      title: "Emp-Id",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department) =>
        department?.name == null ? "-" : department?.name,
    },
  ];

  const showDeleteConfirm = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this meeting?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteMeeting(id);
      }
    });
  };
  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  const deleteMeeting = async (id) => {
    try {
      console.log(id)
      const response = await axios.delete(`/meeting/${id}`);
      toast.success("Meeting deleted successfully");
      dispatch(loadAllMeeting('meeting/'));
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    const data = `meeting/`
    dispatch(loadAllMeeting(data));
  }, []);

  return (
    <div>
        <Table
          scroll={{ x: true }}
          loading={loading}
          dataSource={MeetingAll ? addKeys(MeetingAll) : []}
          columns={columns}
        />
      
      <Modal
        width={"50%"}
        title="Employee Information"
        className="global-modal-custom emp_profile_modal"
        open={swape}
        onOk={() => setSwape(false)}
        onCancel={() => setSwape(false)}
        footer={null}>
        <Table
          scroll={{ x: true }}
          loading={loading}
          dataSource={singleUser}
          columns={MembersColumns}
        />
      </Modal>
    </div>
  );
};

export default MeetingTeamDetails;
